import logo from './a.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
       <br></br>
       <br></br>
       <br></br>
         Welcome to CTOR!
        <br>
        </br>
        <code class="App-link">
          we are under development.
        </code>
      </header>
      
    </div>
    
  );
}

export default App;
